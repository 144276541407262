<template>
  <div class="storefront">
    <div class="inner-wrapper">
      <div class="storefront__content">
        <!-- Messages about delays/issues with the store go below -->
        <div
            v-if="cms.page.fgStoreFrontNotification !== ''"
            v-html="cms.page.fgStoreFrontNotification"
            class="important-message"
        ></div>

        <div v-if="productStore.getAreProductsLoaded" class="storefront__content__greet">
          <div class="storefront__content__greet__title">
            <H2 :title="h2.title" :color="h2.color" :id="h2.id"/>
          </div>
          <p class="storefront__content__greet__text first">
            {{ cms.page.fgTheFollowingItemsAreAvailable }}.
          </p>
          <p class="storefront__content__greet__text first">
            <router-link class="click-here"
                         :to="{ name: 'FAQs', hash: '#store', params: { openQuestion: 'return-policy' } }">
              <strong>{{ cms.page.fgClickHere }}</strong>
            </router-link>
            {{ cms.page.fgToLearnAboutReturnPolicy }}
          </p>
          <p class="storefront__content__greet__text">
            {{ cms.page.fgAllTestimony }}
          </p>
          <div class="storefront__content__greet__buttons">
            <button class="storefront__content__greet__buttons__button btn btn-default btn-store-front">
              <router-link :to="{ name: 'FAQs', hash: '#store' }" class="btn-store-link-blue">
                {{ cms.page.fgStoreFAQs }}
              </router-link>
            </button>
            <button class="storefront__content__greet__buttons__button--blue btn btn-default btn-store-front">
              <router-link :to="{ name: 'Witness' }" class="btn-store-link-white">
                {{ cms.page.fgWitnessingHelp }}
              </router-link>
            </button>
          </div>
        </div>
        <div v-for="product in productStore.getAllStoreProducts" class="storefront__content__product--wrp">
          <div class="storefront__content__product">
            <router-link :to="{ name: 'StoreProducts', params: { id: product.specialProductId }}" class="image-link">
              <div class="storefront__content__product__img">
                <img :src="product.imagePath" :alt="product.title" class="img-fluid">
              </div>
            </router-link>
            <div class="storefront__content__product--descriptionWrp">
              <div class="storefront__content__product__img__input">
                <input-number
                    v-if="productStore.getAreProductsLoaded"
                    v-model.number="localCart[product.specialProductId]"
                />
              </div>

              <router-link class="product-title"
                           :to="{ name: 'StoreProducts', params: { id: product.specialProductId }}">
                <p class="storefront__content__product__title">
                  {{ product.title }}
                </p>
                <p class="storefront__content__product__price">
                  {{ this.showFormattedPrice(product.price) }}
                </p>
              </router-link>
              <button
                  type="button"
                  @click="addToCart(product.specialProductId)"
                  class="storefront__content__product__button btn btn-default"
              >
                <!-- Working state -->
                <span
                    v-if="product.specialProductId === cart.specialProductId && cart.isUpdating && !cart.errorDuringUpdate"
                >
                  {{ cms.page.fgUpdating }}
                  <i class="fa-solid fa-spinner fa-spin margin-icon-left"></i>
                </span>

                <!-- Work completed, but ERROR state (switched off by a JS Timeout function) &ndash;&gt;-->
                <span v-else-if="product.specialProductId === cart.specialProductId && cart.errorDuringUpdate">
                  {{ cms.page.fgError }}
                  <i class="fa-solid fa-circle-exclamation fa-lg margin-icon-left"></i>
                </span>

                <!-- Default state -->
                <span v-else="product.specialProductId !== cart.specialProductId">{{ cms.page.fgAddToCart }}</span>

              </button>
            </div>

            <router-link :to="{ name: 'StoreProducts', params: { id: product.specialProductId }}">
              <div class="storefront__content__product__details">
                {{ cms.page.fgViewDetails }}
              </div>
            </router-link>
          </div>


        </div>

      </div>
    </div>

  </div>


</template>

<script>
import InputNumber from "../components/inputNumber"
import TitlePage from "../components/TitlePage"
import H2 from '../components/H2'
import {mapStores} from 'pinia'
import {useAuthStore} from "@/stores/authStore"
import {useCmsStore} from "@/stores/cmsStore"
import {useDesignStore} from "@/stores/designStore"
import {useProductStore} from "@/stores/productStore"
import {storeMixin} from "@/mixins/storeMixin"

export default {
  name: 'Store',
  mixins: [storeMixin],
  components: {
    TitlePage,
    InputNumber,
    H2
  },
  data() {
    return {
      cart: {
        errorDuringUpdate: false,
        isUpdating: false,
        recentlyUpdated: false,
        specialProductId: '',
      },
      cms: {
        loaded: false,  // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: ["h2", "input_number", "title_page"],
          LanguageCode: "",
          PageName: "store_front",
          MinistryUpdates: false
        },
        page: {},
      },
      h2: {
        title: 'Welcome to the Store!',
        id: 't1',
        color: 'color-black',
      },
      localCart: {},
      areProductsLoaded: false,
      isCartLoaded: false,
    }
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore, useProductStore),
  },
  async created() {
    this.localCart = {}
    await this.productStore.getCartItems(this.authStore.getRegistrationId, true)
    this.setCartToInitialState()
    this.setContentLoaded(false)
    this.setTitlePageFromCms()
    this.areProductsLoaded = await this.productStore.getAllProducts()
    this.resetLocalCartQuantities(false)
    await this.fetchCmsData()
  },
  async mounted() {
    this.designStore.setIsLogo(true);
    this.resetLocalCartQuantities(false)
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setTitlePageFromCms(results)
              this.setContentLoaded(true)
              this.h2.title = this.cms.page.fgWelcomeMessage
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgStoreProducts ? page.fgStoreProducts : 'Store'
      const pageSubtitle = page && page.fgStoreFront ? page.fgStoreFront : 'Items for Personal Witnessing'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: pageSubtitle,
        img_class: 'bg-storeProduct',
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/abstracts/_color.scss';
@import '../assets/scss/abstracts/_variables.scss';


.h-100 {
  height: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.section-offset .inner-wrapper {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 50px 15px;
}

.storefront {
  @media (max-width: 991px) {
    opacity: 0;
    animation: fadeIn .33s ease forwards 1.33s;
  }

  &__content {
    @media (min-width: 992px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 58px;
      column-gap: 69px;
    }


    &__greet {
      @media (min-width: 992px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }


      &__title {
        margin-bottom: 45px;

        @media screen and (max-width: 767px) {
          font-size: 25px;
          line-height: 36px;
          font-weight: 400;
          margin-bottom: 25px;
        }

        &:after {
          @media screen and (max-width: 767px) {
            margin-top: 15px;
            height: 7px;
          }
        }
      }

      &__text {
        margin-bottom: 32px;

        &__link {
          color: #A7191E;
          font-weight: 700;
          transition: all .33s ease;

          &:hover {
            color: $blue;
          }
        }

        &.first {
          @media screen and (max-width: 767px) {
            margin-bottom: 33px;
          }
        }

        @media screen and (max-width: 767px) {
          font-size: 15px;
          line-height: 30px;
          font-weight: 400;
          margin-bottom: 23px;
        }
      }

      &__buttons {
        display: flex;

        &__button {
          cursor: pointer;

          &:first-child {
            margin-right: 50px;

            @media screen and (max-width: 767px) {
              margin-right: 10px;
            }
          }

          &--blue {
            color: #fff;

            &:before {
              background-color: #0C2E3C;
            }
          }
        }

        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
        }
      }

      @media screen and (max-width: 767px) {
        grid-column-end: 2;
        margin-bottom: 42px;
      }
    }

    &__product {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 353px;

      &--wrp {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 767px) {
          display: block;
          margin-bottom: 35.5px;
          border-bottom: 1px solid #C2CACF;
          padding-bottom: 15.5px;

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      &__img {
        position: relative;
        width: 353px;
        height: 353px;
        cursor: pointer;

        &__input {
          position: relative;
          height: 50px;
          width: 100px;
          transform: translateY(-50%) translateX(-50%);
          text-align: center;
          left: 50%;

          &__arrow {
            position: absolute;
            top: 7px;
            right: 11px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 20px;
            height: 20px;
            padding-bottom: 3px;
            cursor: pointer;

            &:last-child {
              position: absolute;
              align-items: flex-start;
              bottom: 6px;
              top: unset;
              padding-top: 3px;
              padding-bottom: unset;
            }

            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
            }
          }

          input {
            height: 50px;
            width: 100px;
            padding: 12px 15px 14px 15px;
            font-size: 18px;
            font-weight: 500;
            background: #fff;
            border: 1px solid #28677D;
            border-radius: 3px;
            outline: none;
            color: #707070;

            @media screen and (max-width: 767px) {
              width: 100%;
              height: 45px;
            }
          }

          @media screen and (max-width: 767px) {
            order: 3;
            transform: none;
            margin-bottom: 15px;
            max-width: 141px;
            width: 100%;
            left: unset;
          }
        }

        @media screen and (max-width: 767px) {
          width: 175px;
          height: 175px;
          margin: 0 auto;
        }
      }

      &__title {
        color: #26687d;
        text-align: center;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          order: 1;
          margin-top: 0;
          text-align: right;
        }
      }

      &__price {
        text-align: center;
        font-size: 18px;
        line-height: 33px;
        margin-bottom: 7px;

        @media screen and (max-width: 767px) {
          order: 2;
          font-size: 16px;
          margin-bottom: 15px;
          text-align: right;
        }
      }

      &__button {
        cursor: pointer;
        margin-bottom: 11px;
        color: #313131;

        @media screen and (max-width: 767px) {
          order: 4;
          min-width: unset;
          margin-bottom: 0;
          font-size: 14px;
        }
      }

      &__details {
        color: #28677D;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          text-align: center;
          font-size: 11px;
          line-height: 15px;
        }
      }

      &.empty {
        @media screen and (max-width: 1635px) {
          display: none;
        }
      }

      &--descriptionWrp {
        text-align: center;

        @media screen and (max-width: 767px) {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0 0 0 auto;
          align-items: flex-end;
          grid-column-end: 3;
          grid-column-start: 2;
          grid-row-end: 3;
          grid-row-start: 1;
        }
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 1635px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 1215px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      row-gap: 0;
      column-gap: 0;
    }
  }
}

.storefront {
  .inner-wrapper {
    .storefront__content {
      padding: 50px;

      @media screen and (max-width: $sm) {
        padding: 50px 25px;
      }

      @media (max-width: 991px) {
        display: block;
      }
      @media (min-width: 992px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-start;
        column-gap: 75px;
        row-gap: 50px;
      }

      &__greet {
        @media (max-width: 991px) {
          margin-bottom: 50px;
        }
        @include MQ(M) {
          flex-basis: calc(((100% / 4) * 2) - 50px);
        }
        @include MQ(L) {
          flex-basis: calc((50%) - 50px);
        }
      }

      &__product--wrp {
        @include MQ(S) {
          flex-basis: 100%;
        }
        @include MQ(M) {
          flex-basis: calc(((100% / 4) * 2) - 50px);
        }
        @include MQ(L) {
          flex-basis: calc((100% / 4) - 50px);
        }
      }

      &__product {
        padding-bottom: 10px;
        width: 100%;
        @include MQ(S) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        > a {
          &:not(.image-link) {
            @include MQ(S) {
              display: none;
            }
          }

          @include MQ(S) {
            flex-basis: 150px;
          }
        }

        .image-link:after {
          @include MQ(S) {
            content: "VIEW DETAILS";
            display: block;
            position: relative;
            width: 100%;
            text-align: center;
            margin-top: 15px;
            color: $blue;
            font-weight: 500;
          }
        }

        &__img {
          background-color: transparent !important;
          width: 100%;

          img {
            background-color: transparent !important;
            object-fit: contain;
            height: 100%;
            width: 100%;
            box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
          }
        }

        &--descriptionWrp {
          @include MQ(S) {
            flex-basis: calc(100% - 200px);
          }

          a {
            color: $blue-darker;
            transition: all .33s ease;

            .storefront__content__product__title {
              margin-bottom: 0;
              font-weight: 500;
            }

            &:hover {
              color: $blue;
            }
          }

        }
      }
    }
  }
}

.disable-button-quantity {
  cursor: not-allowed !important;
}

.margin-icon-left {
  margin-left: 12px;
}

.click-here,
.click-here:link,
.click-here:visited,
.click-here:hover,
.click-here:active, {
  color: $blue;
}

a.btn-store-link-white {
  color: white;
}

a.btn-store-link-blue {
  color: $blue-darker;
}

@media screen and (max-width: $sm) {
  .btn-store-front {
    flex: 0 0 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (min-width: $sm) {
  .btn-store-front {
    flex-basis: 50%;
  }
}

.important-message {
  background-color: $red;
  color: white;
  font-size: 16px;
  padding: 20px 24px;
  border-radius: 10px;
  line-height: 140%;
}

@media screen and (max-width: $sm) {
  .important-message  {
    margin-bottom: 50px;
  }
}
</style>
